<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 text-center pointer"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('retail_profit')">
							{{ translate('retail_profit') }}
							<sort field="retail_profit" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('fast_start_bonus')">
							{{ translate('fast_start_bonus') }}
							<sort field="fast_start_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('team_commission')">
							{{ translate('team_commission') }}
							<sort field="team_commission" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('leadership_matching_bonus')">
							{{ translate('leadership_matching_bonus') }}
							<sort field="leadership_matching_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('lifestyle_bonus')">
							{{ translate('lifestyle_bonus') }}
							<sort field="lifestyle_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('promotions')">
							{{ translate('promotions') }}
							<sort field="promotions" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('total')">
							{{ translate('total') }}
							<sort field="total" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							class="align-middle">
							<span
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.id.toString() })">
								{{ item.id }}
							</span>
						</td>
						<td
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="text-center align-middle">
							<img
								:src="getFlag(item.attributes.country)"
								class="mr-2">
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.retail_profit }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.fast_start_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.team_commission }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.leadership_matching_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.lifestyle_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.promotions }}
						</td>
						<td class="align-middle text-right">
							<b>{{ item.attributes.total }}</b>
						</td>
					</tr>
					<tr class="text-right">
						<td colspan="4">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.retail_profit }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.fast_start_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.team_commission }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.leadership_matching_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.lifestyle_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.promotions }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report as CO,
	Grids,
	Tooltip,
	TransactionCategories,
	Countries,
} from '@/translations';
import { SEARCH_USERS } from '@/config/endpoint';
import Reports from '@/util/Report';
import sort from '@/components/SortArrow';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'TotalEarningsSummary',
	components: { sort },
	mixins: [FiltersParams, DashboardRedirect],
	messages: [CO, Grids, Tooltip, TransactionCategories, Countries],
	data() {
		return {
			totalEarnings: new Reports(),
			endpoint,
		};
	},
	computed: {
		loading() {
			return !!this.totalEarnings.data.loading;
		},
		errors() {
			return this.totalEarnings.data.errors;
		},
		pagination() {
			return this.totalEarnings.data.pagination;
		},
		data() {
			try {
				const { data } = this.totalEarnings.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.totalEarnings.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.totalEarnings.getTotalEarnings();
	},
	methods: {
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.totalEarnings.getTotalEarnings(options);
		},
	},
};
</script>
